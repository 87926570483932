import styled from 'styled-components'

export const FooterWrapper = styled.footer`
  background-color: #06101a;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`
