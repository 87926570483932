export const breakpointsWithoutPixels = {
  xs: 480,
  sm: 768,
  md: 992,
  lg: 1200,
}

export const breakpoints = {
  xs: `${breakpointsWithoutPixels.xs}px`,
  sm: `${breakpointsWithoutPixels.sm}px`,
  md: `${breakpointsWithoutPixels.md}px`,
  lg: `${breakpointsWithoutPixels.lg}px`,
}
